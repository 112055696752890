import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { FileInput } from 'hds-react';
import PlaygroundPreview from '../../../components/Playground';
import TabsLayout from './tabs.mdx';
import InternalLink from '../../../components/InternalLink';
export const FileInputExample = () => {
  const [file, setFile] = React.useState([]);
  console.log('selected file', file);
  return <FileInput id="file-input" label="Choose a file" language="en" maxSize={1.5 * 1024 * 1024} accept=".png,.jpg" onChange={setFile} mdxType="FileInput" />;
};
export const FileInputDefault = () => {
  const [file, setFile] = React.useState([]);
  console.log('selected file', file);
  return <FileInput id="file-input-default" label="Choose a file" language="en" maxSize={1.5 * 1024 * 1024} accept=".png,.jpg" onChange={setFile} mdxType="FileInput" />;
};
export const FileInputDragAndDrop = () => {
  const [files, setFiles] = React.useState([]);
  console.log('selected files', files);
  return <FileInput multiple dragAndDrop id="file-input-drag-and-drop" label="Drag and drop files here" language="en" accept=".png,.jpg" onChange={setFiles} mdxType="FileInput" />;
};
export const _frontmatter = {
  "slug": "/components/file-input",
  "title": "FileInput",
  "navTitle": "FileInput"
};
const layoutProps = {
  FileInputExample,
  FileInputDefault,
  FileInputDragAndDrop,
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}>{`Usage`}<a parentName="h2" {...{
        "href": "#usage",
        "aria-label": "usage permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "example",
      "style": {
        "position": "relative"
      }
    }}>{`Example`}<a parentName="h3" {...{
        "href": "#example",
        "aria-label": "example permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>

    <PlaygroundPreview mdxType="PlaygroundPreview">
  <FileInputExample mdxType="FileInputExample" />
    </PlaygroundPreview>
    <h3 {...{
      "id": "principles",
      "style": {
        "position": "relative"
      }
    }}>{`Principles`}<a parentName="h3" {...{
        "href": "#principles",
        "aria-label": "principles permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`A label should always be provided with a FileInput. It should clearly describe what the user is expected to select.`}</li>
      <li parentName="ul">{`The helper text below the FileInput describes the file requirements to the user. It lists accepted file formats as well as the maximum size of a single file.`}
        <ul parentName="li">
          <li parentName="ul">{`Note that the helper text is generated automatically from the `}<inlineCode parentName="li">{`accept`}</inlineCode>{` and `}<inlineCode parentName="li">{`maxSize`}</inlineCode>{` properties.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`The FileInput does not automatically upload files to any server. The user is required to submit the form before the actual upload happens.`}</li>
      <li parentName="ul">{`One FileInput can be configured to accept one or multiple files.`}
        <ul parentName="li">
          <li parentName="ul">{`Make sure labels of the input indicate the number of expected files to the user.`}</li>
          <li parentName="ul">{`A FileInput allowing multiple files fits well for situations where all the files are related to each other (e.g. multiple photos from the same event).`}</li>
          <li parentName="ul">{`If the user has to upload multiple files that are not related to each other, prefer using multiple file uploads - one for each file.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Dragging and dropping files can be allowed by using the `}<inlineCode parentName="li">{`dragAndDrop`}</inlineCode>{` property.`}</li>
      <li parentName="ul">{`The FileInput uses an info text element to inform the user about the status of the component. You can read more about the usage of the info text element in the `}<InternalLink href="/patterns/forms/form-validation#3-info-message" mdxType="InternalLink">{`HDS Form validation pattern documentation page`}</InternalLink>{`.`}</li>
      <li parentName="ul">{`The FileInput component resolves human-readable file size abbreviations based on a binary system. The file size texts are shown after the selected file name and in maxSize messages if maxSize validation is used.`}</li>
    </ul>
    <h3 {...{
      "id": "variations",
      "style": {
        "position": "relative"
      }
    }}>{`Variations`}<a parentName="h3" {...{
        "href": "#variations",
        "aria-label": "variations permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <h4 {...{
      "id": "default",
      "style": {
        "position": "relative"
      }
    }}>{`Default`}<a parentName="h4" {...{
        "href": "#default",
        "aria-label": "default permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`In its default form, the HDS FileInput offers a button-like element that opens a file browser native to the user's current device. Selected files are presented in a list below the input and files can be removed from the listing separately.`}</p>

    <PlaygroundPreview mdxType="PlaygroundPreview">
  <FileInputDefault mdxType="FileInputDefault" />
    </PlaygroundPreview>
    <h4 {...{
      "id": "with-drag-and-drop",
      "style": {
        "position": "relative"
      }
    }}>{`With drag and drop`}<a parentName="h4" {...{
        "href": "#with-drag-and-drop",
        "aria-label": "with drag and drop permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`If in some cases having the ability to drag and drop files onto the page could help the user, this can be enabled with the `}<inlineCode parentName="p">{`dragAndDrop`}</inlineCode>{` property. Note that the traditional file input is still included below the drag and drop area. Therefore this property does not weaken the accessibility of the component.`}</p>

    <PlaygroundPreview mdxType="PlaygroundPreview">
  <FileInputDragAndDrop mdxType="FileInputDragAndDrop" />
    </PlaygroundPreview>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      